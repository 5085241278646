<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) A metal nitride has a nitrogen atom at each corner and a metal atom at each edge. Which
        of the following indicates the appropriate empirical formula for this nitride?
      </p>

      <v-radio-group v-model="inputs.empFormula" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) What is the formula of the compound that crystallizes with
        <chemical-latex :content="metal" />
        ions occupying one-half of the cubic holes in a simple cubic arrangement of
        <chemical-latex :content="halogen" />
        ions?
      </p>

      <chemical-notation-input
        v-model="inputs.molFormula"
        :show-note="true"
        group-size="sm"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question404',
  components: {
    StembleLatex,
    ChemicalLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        empFormula: null,
        molFormula: null,
      },
      options: [
        {text: '$\\ce{Ba3N2}$', value: 'Ba3N2'},
        {text: '$\\ce{Na3N}$', value: 'Na3N'},
        {text: '$\\ce{AlN}$', value: 'AlN'},
        {text: '$\\ce{Ti3N4}$', value: 'Ti3N4'},
      ],
    };
  },
  computed: {
    metalVersion() {
      return this.taskState.getValueBySymbol('metalVersion').content;
    },
    metal() {
      if (this.metalVersion.value === 1) {
        return 'Ba^2+';
      } else if (this.metalVersion.value === 2) {
        return 'Ca^2+';
      } else {
        return 'Mg^2+';
      }
    },
    halogenVersion() {
      return this.taskState.getValueBySymbol('halogenVersion').content;
    },
    halogen() {
      if (this.halogenVersion.value === 1) {
        return 'fluoride';
      } else if (this.halogenVersion.value === 2) {
        return 'chloride';
      } else {
        return 'bromide';
      }
    },
  },
};
</script>
