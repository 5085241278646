<template>
  <v-text-field
    v-format-symbol="{enforceLength: enforceLength, enforceCase: enforceCase}"
    :solo="solo"
    :flat="flat"
    :outlined="outlined"
    hide-details="auto"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-if="prependText !== null" v-slot:prepend-inner>
      <stemble-latex :content="prependText" />
    </template>
    <template v-if="appendText !== null" v-slot:append>
      <stemble-latex :content="appendText" />
    </template>
  </v-text-field>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemicalSymbolInput',
  components: {StembleLatex},
  directives: {
    formatSymbol: {
      bind(el, binding) {
        let handler = function (e) {
          if (e.target.value) {
            e.target.value = e.target.value.trim();

            if (binding.value.enforceCase) {
              e.target.value =
                e.target.value.substr(0, 1).toUpperCase() + e.target.value.substr(1).toLowerCase();
            }

            if (binding.value.enforceLength) {
              e.target.value = e.target.value.substr(0, Math.min(2, e.target.value.length));
            }

            e.target.dispatchEvent(new Event('input'));
          }
        };
        el.addEventListener('input', handler);
      },
    },
  },
  inheritAttrs: false,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    prependText: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
    enforceLength: {
      type: Boolean,
      default: true,
    },
    enforceCase: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
